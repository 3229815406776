let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Cody Lau ",
    "role": "Full-Stack Web Developer",
    "linkedinId":"https://www.linkedin.com/in/cody-lau-11913b28a/",
    "skypeid": "Your skypeid",
    "roleDescription": ` with a background in health science and Paramedicine. Passionate about team collaboration, I strive to create tailored web applications that meet clients' needs.
    With a keen aptitude for rapid learning and adaptation, I am poised to contribute effectively to any future projects.`, 
    "socialLinks": [
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/cody-lau-11913b28a/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/laucodx45",
          "className":"fa fa-github"
        }
      ],
    "aboutme":`I am a recent graduate of the Lighthouse Labs Web Development diploma program. 
    I am passionate about using my newly acquired programming skills to collaborate with other developers 
    in creating solutions that address real-world problems and enhance users' lives.`,
    "address":"Victoria, BC, Canada",
    "website":"lau-cody@outlook.com",
    "education":[
      {
        "UniversityName":"Lighthouse Labs",
        "specialization":"Web Development Diploma",
        "MonthOfPassing":"May",
        "YearOfPassing":"2024"
      },
      {
        "UniversityName":"University of Victoria",
        "specialization":"Bachelor of Science In Psychology",
        "MonthOfPassing":"June",
        "YearOfPassing":"2023"
      },
      {
        "UniversityName": "Justice Institute of British Columbia",
        "specialization": "Primary Care Paramedic",
        "MonthOfPassing": "February",
        "YearOfPassing": "2018"
      }
    ],
    "work":[
      {
        "CompanyName":"BC Emergency Health Services",
        "specialization":"Paramedic",
        "MonthOfLeaving":"2020",
        "YearOfLeaving":"- current"
      }
    ],
    "skillsDescription":"",
    "skills": [
      {
        "skillname": "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg"
      },
      {
        "skillname": "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/ruby/ruby-original-wordmark.svg"
      },
      {
        "skillname": "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original-wordmark.svg"
      },
      {
        "skillname": "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/azuresqldatabase/azuresqldatabase-original.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/express/express-original-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/jquery/jquery-plain-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rails/rails-plain-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/materialui/materialui-original.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/postgresql/postgresql-plain-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/git/git-original-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/jest/jest-plain.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/cypressio/cypressio-original-wordmark.svg"
      },
      {
        "skillname" : "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mocha/mocha-original.svg"
      },
    ],
    "portfolio":[
      {
        "name":"Yes, Chef",
        "description": [
          `Yes, Chef is an all in one cooking assistant web app built with React, Express, powered by ChatGPT. 
          User can add, delete pantry items and generate AI recipes.
          Users can view their saved-recipes, ask cooking related questions to their AI chat assistance. 
          Users can also choose from a few pre-loaded personalities for their AI chatbot.`
        ],
        "imgurl":[
          "/images/yeschef1.png",
          "/images/yeschef2.png",
          "/images/yeschef3.png",
          "/images/yeschef4.png",
          "/images/yeschef5.png",
          "/images/yeschef6.png",
        ]
      },
      {
        "name":"Photolabs",
        "description": [
          `
          React Photo Viewer is a single-page application that allows users to view photos, browse different categories, 
          like pictures, and navigate through a multi-page interface. 
          The application is connected to a PostgreSQL database to store and retrieve photo data.`
        ],
        "imgurl":[
          "/images/photolabs1.png",
          "/images/photolabs2.png",
          "/images/photolabs3.png",
          "/images/photolabs4.png",
          "/images/photolabs5.png"
        ]
      },
      {
        "name":"Food Ordering App",
        "description": [
          `A food ordering app with SMS notifications for new orders and pickup time updates.
          Users can edit and submit orders in the cart section.
          Once the order is submitted, the admin gets an SMS notification, and users can access their order history.
          Admin can input the time required to complete the order, and the user receives an SMS notification.
          Once the order is completed, the status changes to completed, and the user gets another SMS notification.`
        ],
        "imgurl":[
          "/images/foodOrdering2.png", 
          "/images/foodOrdering1.png",
          "/images/foodOrdering3.png",
          "/images/foodOrdering4.png",
          "/images/foodOrdering5.png"
      ]
      },
      {
        "name": "LightBnb",
        "description": [
          `LightBnb is a simple multi-page Airbnb clone that uses server-side 
          Javascript to display the information from queries to web pages via SQL queries
          This project focuses on designing a robust relational database, 
          the LightBnb database has been meticulously designed with normalization in mind, 
          ensuring efficient storage and retrieval of data. The database is responsible for storing property details, 
          user information, reservations, and reviews.`
        ],
        "imgurl":[
          "/images/lightbnb1.png",
          "/images/lightbnb2.png",
          "/images/lightbnb3.png",
          "/images/lightbnb4.png",
          "/images/lightbnb5.png"
        ]
      },
      {
        "name": "Tweeter",
        "description": [
          `Tweeter is a simple, single-page Twitter clone. 
            Tweeter provides users with a simple platform to share their thoughts, commonly known as tweets.
            This web application implements a combination of front-end skills such as using HTML, CSS, JS, jQuery, and AJAX, 
            along with back-end skills utilizing Node.js and Express.`
        ],
        "imgurl": [
          "/images/tweeter1.png",
          "/images/tweeter2.png",
          "/images/tweeter3.png"
        ]
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData